import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: 'https://8c06331cbfe04f649595da140d8ffb1b@o447795.ingest.sentry.io/5879345',
    ignoreErrors: [
        'NetworkError',
        'Network Error',
        'Non-Error promise rejection captured',
        'timeout',
        'Failed to fetch',
        'Load failed',
        'Event `ErrorEvent` captured as exception with message `Script error.`'
    ],
    ignoreTransactions: [
        new RegExp("__gCrWeb", 'g')
    ],
    allowUrls: [
        'https://cdn2-courses.iphonephotographyschool.com',
        'https://courses.iphonephotographyschool.com'
    ],
    logErrors: false,
    environment: window.App.appEnvironment,
});
export const errorHandler = (err) => Sentry.captureException(err)
window.addEventListener('error', errorHandler)
